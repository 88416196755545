/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const dashboard = lazy(() => import('views/dashboard/Dashboard'));
const stores = {
  approvalRequest: lazy(() => import('views/stores/approvalRequest/EditableRows/EditableRows')),
  approvedList: lazy(() => import('views/stores/approvedList/EditableRows')),
  detailDocument: lazy(() => import('views/stores/approvalRequest/detail/ProductsDetail')),
  storeDetail: lazy(() => import('views/stores/approvedList/components/CustomersDetail')),
  allStoreOrders: lazy(() => import('views/stores/approvedList/components/ViewAllStoreOrders')),
  singleOrderDetail: lazy(() => import('views/stores/approvedList/components/SingleStoreOrderDetail')),
  faqs: lazy(() => import('views/stores/faqs/Faqs')),
  singleFaqDetail: lazy(() => import('views/stores/faqs/components/SingleFaqDetail')),
  aboutus: lazy(() => import('views/stores/AboutUs')),
  privacypolicy: lazy(() => import('views/stores/PrivacyPolicy')),
  termscondition: lazy(() => import('views/stores/TermsCondition')),
};
const taxManagement = lazy(() => import('views/TaxManagement/TaxManagement'));
const serviceManagement = lazy(() => import('views/ServiceManagement/serviceManagement'));
const users = {
  approvalRequest: lazy(() => import('views/users/approvalRequest/EditableRows/EditableRows')),
  approvedList: lazy(() => import('views/users/approvedList/EditableRows')),
  detailDocument: lazy(() => import('views/users/approvalRequest/detail/ProductsDetail')),
  userDetail: lazy(() => import('views/users/approvedList/components/CustomersDetail')),
  allUserOrders: lazy(() => import('views/users/approvedList/components/ViewAllUserOrders')),
  singleOrderDetail: lazy(() => import('views/users/approvedList/components/SingleUserOrderDetail')),
  faqs: lazy(() => import('views/users/faqs/Faqs')),
  singleFaqDetail: lazy(() => import('views/users/faqs/components/SingleFaqDetail')),
  aboutus: lazy(() => import('views/users/AboutUs')),
  privacypolicy: lazy(() => import('views/users/PrivacyPolicy')),
  termscondition: lazy(() => import('views/users/TermsCondition')),


};


const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;
const routesAndMenuItems = {
  mainMenuItems: [
    // {
    //   path: DEFAULT_PATHS.APP,
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/login`,
    // },
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard`,
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'Dashboard',
      icon: 'shop',
    },
    {
      path: `${appRoot}/stores`,//stores
      exact: true,
      redirect: true,
      to: `${appRoot}/stores`,
      label: 'Stores',
      icon: 'cart',
      subs: [
        { path: '/approvalRequest', label: 'Store Approval Requests', component: stores.approvalRequest },//approval request
        { path: '/approvedList', label: 'Approved Stores', component: stores.approvedList }, //approved list
        { path: '/aboutus', label: 'About Us', component: stores.aboutus }, //Faqs
        { path: '/privacy', label: 'Privacy Policy', component: stores.privacypolicy }, //Faqs
        { path: '/terms', label: 'Terms & Conditions', component: stores.termscondition }, //Faqs

        { path: '/faqs', label: 'FAQS', component: stores.faqs }, //Faqs

        { path: '/detailDocument/:id', label: '', component: stores.detailDocument }, //detail document
        { path: '/storedetail/:id', label: '', component: stores.storeDetail }, // store detail
        { path: '/viewallstoreorders/:id', label: '', component: stores.allStoreOrders }, // store orders
        { path: '/order/detail/:id', label: '', component: stores.singleOrderDetail } ,// store orders
        { path: '/faq/detail/:id', label: '', component: stores.singleFaqDetail } 

      ],
    },
    
    {
      path: `${appRoot}/tax-management`,
      component: taxManagement,
      label: 'Tax Management',
      icon: 'dollar',
    },
    {
      path: `${appRoot}/service-management`,
      component: serviceManagement,
      label: 'Service Management',
      icon: 'shop',
    },
    {
      path: `${appRoot}/users`,//users
      exact: true,
      redirect: true,
      to: `${appRoot}/users/list`,
      label: 'Users',
      icon: 'user',
      subs: [
        { path: '/approvalRequest', label: 'User Approval Requests', component: users.approvalRequest },//approval request
        { path: '/approvedList', label: 'Approved Users', component: users.approvedList }, //approved list
        { path: '/aboutus', label: 'About Us', component: users.aboutus }, //Faqs
        { path: '/privacy', label: 'Privacy Policy', component: users.privacypolicy }, //Faqs
        { path: '/terms', label: 'Terms & Conditions', component: users.termscondition }, //Faqs

        { path: '/faqs', label: 'FAQS', component: users.faqs }, //Faqs

        { path: '/detailDocument/:id', label: '', component: users.detailDocument }, //detail document
        { path: '/userdetail/:id', label: '', component: users.userDetail } ,//user detail
        { path: '/viewalluserorders/:id', label: '', component: users.allUserOrders }, // store orders
        { path: '/order/detail/:id', label: '', component: users.singleOrderDetail } ,
        { path: '/faq/detail/:id', label: '', component: users.singleFaqDetail } 

      ],
    },
    
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
