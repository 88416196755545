import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteItem = ({ redirect = false, exact = false, path, to, component }) => {
//   const [isAuthenticated,setIsAuthenticated]=useState(false);
//   const verifyAdmin = async () => {
//     axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/verify`, {
//       headers: { "authorization": `Bearer ${localStorage.getItem("token")}` }
//     }).then((res) => {
    
//       if (!res.data.success) {
//         setIsAuthenticated(false)
//        }else{
//         setIsAuthenticated(true)
//        }
//     }).catch((err) => {
//       if (err?.response?.status === 401) {
//        setIsAuthenticated(false)
//       } else {
//         setIsAuthenticated(false)
//       }
//     });
//   }
//   useEffect(()=>{
// verifyAdmin()
//   },[])
  if (!path && !to) {
    return () => {};
  }
  // if(!isAuthenticated){

  // }
  if (redirect) {
    console.log("redirect",redirect);
    const props = {};
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    console.log("redirect props",props);
    return <Redirect {...props} />;
  }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;
  console.log("route props",props);
  // console.log(<Route {...props});
  return <Route {...props} />;
};

export default memo(RouteItem);
